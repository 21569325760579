import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`Glute Ham Raise 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`50-Squats holding a plate (45/25)`}</p>
    <p>{`25-Wall Balls (20/14)`}</p>
    <p>{`50-OH Walking Lunges (45/25)`}</p>
    <p>{`25-Box Jumps (24/20)`}</p>
    <p>{`50-Squats holding a plate (45/25)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Judging for workout 17.1 of the CrossFit Open will continue today
from 10:30-12:30 at The Ville. If you are not able to get in today we
will judge on Monday (rest day) during class for unlimited members. If
you are an open gym member and cannot come in today please contact
Daniel to set up a time on Monday.  `}{`*`}{`*`}{`And remember to submit your
scores on the Games site by Monday at 8:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      